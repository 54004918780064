import React from 'react'
import ButtonLink from '../ButtonLink'
import Overlay from './assets/yellow.jpg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Terms } from '@/types.d'
import Placeholder from './placeholder.png'

export interface CareerRowProps {
  heading: string
  terms: {
    nodes: Array<Terms>
  }
  to: string
  img: any
}

const CareerRow = ({ heading, terms, to, img }: CareerRowProps) => {
  const image: any = img ? getImage(img?.localFile) : false

  return (
    <section className={`grid grid-cols-12 gap-6 lg:gap-20 my-10`}>
      <div className="rounded-3xl bg-white col-span-4 overflow-hidden relative hidden sm:block">
        {image ? (
          <div className="overflow-hidden h-full relative">
            <GatsbyImage
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              className="h-full rounded-3xl z-0"
              alt={img?.alt || 'person image'}
              image={image}
            />
          </div>
        ) : (
          <div className="overflow-hidden">
            <img alt="Henkilö ei kuvaa" className="w-100" src={Placeholder} />
          </div>
        )}
      </div>
      <div
        style={{ backgroundImage: `url(${Overlay})` }}
        className="col-span-12 sm:col-span-8 w-full flex relative overflow-hidden rounded-3xl shadow-md bg-no-repeat bg-cover bg-center"
      >
        <div className="py-5 px-5 md:py-10 md:px-10 lg:py-16 lg:px-20 w-full flex-col flex">
          <div className="mb-10">
            <p className="text-md md:text-2xl md:mb-3">
              {terms?.nodes?.map(term => {
                return <span key={term.id}>{term.name} </span>
              })}
            </p>
            {heading && <h2 className="text-2xl md:text-4xl">{heading}</h2>}
          </div>
          <div className="flex justify-center items-center mt-auto">
            <ButtonLink to={to}>Lue lisää</ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerRow
