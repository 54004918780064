import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import CareerRow from '@/components/CareerRow'

interface Category {
  name: string
  slug: string
  id: string
  uri: string
}

interface DefaultData {
  data: {
    page: {
      title: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
    }
    careerStories: any
    categories: {
      nodes: Array<Category>
    }
    menu?: any
    quickmenu?: any
  }
  pageContext: {
    postlang: string
  }
}

/**
 * Function for queryStrings
 * @returns
 */
export const getQurySring = () => {
  if (typeof location !== 'undefined') {
    const params = new URLSearchParams(location.search)
    const s = params?.get('s')

    return s
  } else {
    return ''
  }
}

const CareersTemplate = ({ data }: DefaultData) => {
  // Page data
  const { page, careerStories, categories } = data

  const { menu, quickmenu } = data
  const s = getQurySring()
  const [type, setType] = useState(s || '')

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
  }

  const filterTerms = (item: any) => {
    if (item?.terms?.nodes.some((t: any) => t?.name === type || type === '')) {
      return true
    } else if ((item?.terms?.nodes.length === 0 && type == '') || (item?.terms === null && type == '')) {
      return true
    }
  }

  const filterResults = () => {
    const FilterTerm: any = careerStories?.nodes?.filter(filterTerms)
    return FilterTerm
  }

  const results = filterResults()

  const setTypeFilter = (currentType: string) => {
    setType(currentType)
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
    >
      <div className="mx-auto max-w-[1100px]">
        <div>
          <button
            className={`hover:text-primary focus:text-primary y-2 mr-10 font-FuturaBol ${
              type === '' ? 'text-primary' : ''
            }`}
            onClick={() => setTypeFilter('')}
          >
            Kaikki
          </button>
          {categories.nodes.map(cat => {
            return (
              <button
                className={`hover:text-primary focus:text-primary y-2 mr-10 font-FuturaBol ${
                  type === cat.name ? 'text-primary' : ''
                }`}
                onClick={() => setTypeFilter(`${cat.name ?? ''}`)}
                key={cat.id}
              >
                {cat.name}
              </button>
            )
          })}
        </div>
        {results?.map((story: any) => {
          return (
            <CareerRow key={story?.id} heading={story?.title} terms={story?.terms} to={story?.uri} img={story?.img} />
          )
        })}
      </div>
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query CareerArhiveById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      ...pageBlocks
      ...seo
      ...featuredHeroImage
      heroFilter: gwShowPostHeaderFilter
    }
    careerStories: allWpGwStory(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        date
        terms {
          nodes {
            name
            id
          }
        }
        img: gwCareerPersonImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    categories: allWpGwStoriesTaxonomySingle {
      nodes {
        name
        slug
        id
        uri
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default CareersTemplate
